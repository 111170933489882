import en from './locales/en/translation.json';
import sv from './locales/sv/translation.json';
import da from './locales/da/translation.json';
import de from './locales/de/translation.json';

export default {
  en,
  sv,
  de,
  da
};
